
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

//import * as Yup from "yup";
// import AttendanceUpdateDrwaer from "@/layout/header/partials/attendance/attendanceupdateDrawer.vue";
import ApiService from '@/core/services/ApiService';
import moment from 'moment';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'allow-dropout-trainee',
  components: {
    Form,
    //Field,
  },
  data() {
    return {
      api_url: '',
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        term_id: '',
        batch_id: '',
        total_terms: '',
      },
      batchList: [],
      termList: [],
      details: {},
      tranches_info: [],
      weekplan: {},
      entityTypes: [],

      courseList: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      trainingCalendar: [] as any,
      instituteList: [],
      componentKey: 0,
      tableData: [],
      form: {},
      loading: false,
      showcalendarDetails: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranches();
    // await this.getEntityTypes();
    await this.getEntityInfos();
    this.emitter.on('submitted-allowed-for-training', async () => {
      this.calenderDetails();
      this.componentKey += 1;
    });
  },
  methods: {
    async formSubmit() {
      this.loading = true;
      let formData = new FormData();
      formData.set('data', JSON.stringify(this.tableData));
      ApiService.post('allowdropouttrainee/save', formData)
        .then((response) => {
          this.loading = false;
          //console.log(response);
          if (response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.emitter.emit('submitted-allowed-for-training', true);
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getEntityInfos() {
      this.loading = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async calenderDetails() {
      if (this.batch.batch_id) {
        this.loading = true;
        await ApiService.get(
          'allowdropouttrainee/list' +
            '?batch_id=' +
            this.batch.batch_id +
            '&term_no=' +
            this.batch.term_id +
            '&course_id=' +
            this.batch.course_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&tranche_id=' +
            this.batch.tranche_id +
            '&institute_id=' +
            this.batch.institute_id
        )
          .then((response) => {
            console.log(response);
            this.loading = false;
            this.tableData = response.data.data;
            this.showcalendarDetails = true;
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select all required fields.',
          icon: 'warning',
        });
      }
    },

    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async termInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_GET_BATCH_TERM_API +
          '?batch_info_id=' +
          this.batch.batch_id
      )
        .then((response) => {
          this.loading = false;
          this.termList = response.data.data;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.batchList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async courseInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          this.batch.institute_id +
          '&term_check=1'
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
